import pro from "./image/profile.png";
import Order from "./image/order.png";
import products from "./image/shopping.png";
import { useEffect, useState } from "react";
import axios from "axios";
import mediaFile from "../Navbar/image/mediaFile.png";
import documentFile from "../Navbar/image/documentFile.png";
import OtherFlies from "../Navbar/image/OtherFlies.png";
import unknown from "../Navbar/image/unknown.png";
import "./Dasboard.css"; // Import the stylesheet
import { Table } from "antd";
const Dashboard = () => {
  const [analyticsData, setAnalyticsData] = useState({
    users: 0,
    products: 0,
    orders: 0,
  });

  useEffect(() => {
    analyticsApi();
  }, []);

  const analyticsApi = async () => {
    try {
      const res = await axios.get("https://bardawamplus.com/api/analytics");
      setAnalyticsData(res.data); // Update the state with the entire analytics data
    } catch (error) {
      console.error("Error fetching analytics data:", error);
    }
  };
  //todo get user
  // eslint-disable-next-line no-unused-vars
  const [totalID, setTotalID] = useState(0);
  const [totalStorage, setTotalStorage] = useState(0);

  useEffect(() => {
    getApiStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getApiStorage = async () => {
    try {
      const res = await fetch(`https://bardawamplus.com/api/analytics`);
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      const data = await res.json();
      const formattedStorage = formatStorageSize(data.usedStorageSpace);
      setTotalStorage(formattedStorage);
      setTotalID(data.users);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const formatStorageSize = (sizeInBytes) => {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (sizeInBytes === 0) return "0 Bytes";
    const i = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
    return (
      parseFloat((sizeInBytes / Math.pow(1024, i)).toFixed(2)) + " " + sizes[i]
    );
  };
  //todo this is table all products
  const [dataSource, setDataSource] = useState([]);
  const getApiProducts = async () => {
    try {
      // Make separate requests for both endpoints
      const [productsRes, pinnedRes] = await Promise.all([
        fetch("https://bardawamplus.com/api/products"),
        fetch("https://bardawamplus.com/api/products/pinned"),
      ]);

      // Check if both requests were successful
      if (!productsRes.ok || !pinnedRes.ok) {
        throw new Error(
          `HTTP error! Status: ${productsRes.status} or ${pinnedRes.status}`
        );
      }

      // Parse the JSON responses
      const productsData = await productsRes.json();
      const pinnedData = await pinnedRes.json();

      // Update the dataSource with the combined data from both responses
      const combinedData = [...productsData.data, ...pinnedData.data];

      setDataSource(
        combinedData.map((item) => {
          const nameObj = JSON.parse(item.name || "{}"); // Safely parse name, default to empty object if undefined
          return {
            key: item.id,
            id: item.id,
            image_url: item.image_url
              ? `https://bardawamplus.com/api/storage/${item.image_url}`
              : null,
            pinned: item.pinned,
            nameKurdish: nameObj.ku || "N/A",
          };
        })
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getApiProducts();
  }, []);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "nameKurdish",
      key: "nameKurdish",
    },
    {
      title: "Image",
      dataIndex: "image_url",
      key: "image_url",
      render: (image_url) => {
        if (image_url) {
          return (
            <img
              src={image_url}
              alt="Product"
              style={{ width: "60px", height: "60px" }}
            />
          );
        } else {
          return "N/A";
        }
      },
    },

    {
      title: "Pinned",
      dataIndex: "pinned",
      key: "pinned",
      render: (pinned) => {
        return (
          <span className={pinned === 0 ? "pinned-products" : "pinned-slider"}>
            {pinned === 0 ? "Products" : "Slider"}
          </span>
        );
      },
    },
  ];

  return (
    <div className="kk">
      <div className="dashboard-container">
        <ul className="list-container">
          <li className="list-item">
            <div className="item-content">
              <span className="icon">
                <img src={pro} alt=" " height={120} width={120} />
              </span>
              <span className="user">Users</span>
              <span className="number">{analyticsData.users}</span>
            </div>
          </li>
          <li className="list-item">
            <div className="item-content">
              <span className="icon">
                <img src={Order} alt=" " height={120} width={120} />
              </span>
              <span className="user">Orders</span>
              <span className="number">{analyticsData.orders}</span>
            </div>
          </li>
          <li className="list-item">
            <div className="item-content">
              <span className="icon">
                <img src={products} alt=" " height={120} width={120} />
              </span>
              <span className="user">products</span>
              <span className="number">{analyticsData.products}</span>
            </div>
          </li>
        </ul>
      </div>
      <Table
        // dataSource={dataSource.map((item) => ({ ...item, key: item.id }))}
        dataSource={dataSource}
        columns={columns}
        scroll={{
          y: 240,
        }}
        style={{
          marginLeft: 50,
          marginRight: 50,
        }}
        pagination={false}
      />
      <h2 className="Storage">Storage Details {totalStorage} of 200 GB</h2>
      <div className="allFlies">
        <ul className="file-list">
          <li>
            <img src={mediaFile} alt="ff" height={70} width={70} />
          </li>
          <li>Media File</li>
          <li>{totalStorage}</li>
        </ul>
        <ul className="file-list">
          <li>
            <img src={documentFile} alt="aa" height={70} width={70} />
          </li>
          <li>Document File</li>
          <li>10.3KB</li>
        </ul>
        <ul className="file-list">
          <li>
            <img src={OtherFlies} alt="ss" height={70} width={70} />
          </li>
          <li>Other Flies</li>
          <li>5.7KB</li>
        </ul>
        <ul className="file-list">
          <li>
            <img src={unknown} alt="dd" height={70} width={70} />
          </li>
          <li>Unknown</li>
          <li>1.1KB</li>
        </ul>
      </div>
    </div>
  );
};

export default Dashboard;
