import {
  Table,
  Input,
  Button,
  Space,
  Modal,
  Form,
  InputNumber,
  message,
} from "antd";

import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const Profile = () => {
  const [SearchText, setSearchText] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);

  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [newBalance, setNewBalance] = useState(0);
  const [selectedUserId, setSelectedUserId] = useState(null);

  useEffect(() => {
    getApiUsers();
  }, []);

  const getApiUsers = async () => {
    try {
      const res = await fetch("https://bardawamplus.com/api/users");
      if (!res.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await res.json();
      const updatedData = data.map((item) => ({
        id: item.id,

        name: item.name,
        email: item.email,
        referential_code: item.referential_code,
        number: item.number,
        points: item.points,
        address: item.address,
        referrals: item.referrals ? item.referrals.length : 0,
        balance: item.balance,
      }));
      setDataSource(updatedData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const savedData = localStorage.getItem("userDataSource");
    if (savedData) {
      setDataSource(JSON.parse(savedData));
    }
  }, []);

  const showConfirmModal = (id) => {
    setDeleteUserId(id);
    setConfirmModalVisible(true);
  };

  const handleDeleteUser = async () => {
    try {
      await axios.delete(`https://bardawamplus.com/api/users/${deleteUserId}`);
      const updatedData = dataSource.filter((item) => item.id !== deleteUserId);
      setDataSource(updatedData);
      localStorage.setItem("userDataSource", JSON.stringify(updatedData));
      setConfirmModalVisible(false);
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  // const [updateModalVisible, setUpdateModalVisible] = useState(false);
  // const [newBalance, setNewBalance] = useState(0);
  // const [selectedUserId, setSelectedUserId] = useState(null);

  // const showUpdateModal = (id) => {
  //   setSelectedUserId(id);
  //   setNewBalance(dataSource.find((item) => item.id === id)?.balance || 0);
  //   setUpdateModalVisible(true);
  // };
  const handleUpdateBalance = async (id, newBalance) => {
    try {
      // Update newBalance value in the local state
      setNewBalance(newBalance);

      // Send PUT request to update balance on the server
      const response = await fetch(
        `https://bardawamplus.com/api/users/${id}/update-balance`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ balance: newBalance }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to update balance");
      }

      // Update the balance in the dataSource state
      const updatedData = dataSource.map((item) => {
        if (item.id === id) {
          return { ...item, balance: newBalance };
        }
        return item;
      });
      setDataSource(updatedData);
      console.log(updatedData);

      // Close the update modal
      setUpdateModalVisible(false);
      message.success("success to change Balance");
      // Print the app variable
      // eslint-disable-next-line no-undef
      // console.log("App variable:", app);
    } catch (error) {
      console.error("Error updating balance:", error);
    }
  };

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      filteredValue: [SearchText],
      onFilter: (value, record) => {
        return (
          String(record.name).toLowerCase().includes(value.toLowerCase()) ||
          String(record.number).toLowerCase().includes(value.toLowerCase()) ||
          String(record.id).toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    // {
    //   title: "uuid",
    //   dataIndex: "uuid",
    //   key: "uuid",
    // },
    {
      title: "name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "referential_code",
      dataIndex: "referential_code",
      key: "referential_code",
    },
    {
      title: "number",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "referrals",
      dataIndex: "referrals",
      key: "referrals",
      render: (text, record) => (
        <Link to={`/Profile/Network/${record.id}`}>{text}</Link>
      ),
    },
    // {
    //   title: "referrals",
    //   dataIndex: "referrals",
    //   key: "referrals",
    //   render: (text, record) => (
    //     <Link to={`/Network/${record.id}`} target="_blank">
    //       {text}
    //     </Link>
    //   ),
    // },
    {
      title: "address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "points",
      dataIndex: "points",
      key: "points",
    },
    {
      title: "balance",
      dataIndex: "balance",
      key: "balance",
      render: (text, record) => (
        <Space size="middle">
          <span>{text}</span>
          <Button
            type="default"
            style={{
              backgroundColor: "green",
              borderColor: "green",
              color: "white",
            }}
            onClick={() => showUpdateModal(record.id)}
          >
            Edit
          </Button>
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="primary"
            danger
            onClick={() => showConfirmModal(record.id)}
          >
            Remove
          </Button>
        </Space>
      ),
    },
  ];
  const showUpdateModal = (id) => {
    const newBalance = dataSource.find((item) => item.id === id)?.balance || 0;
    setNewBalance(newBalance);
    setSelectedUserId(id);
    setUpdateModalVisible(true);
  };

  const handleModalOk = () => {
    handleUpdateBalance(selectedUserId, newBalance);
  };

  return (
    <div>
      <Input.Search
        placeholder="Search here ...."
        style={{
          marginBottom: 15,
          marginTop: 12,
          width: 300,
        }}
        onSearch={(value) => {
          setSearchText(value);
        }}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />
      {loading ? (
        <p>Loading...</p>
      ) : (
        <Table
          dataSource={dataSource.map((item) => ({ ...item, key: item.id }))}
          columns={columns}
        />
      )}
      <Modal
        title="Confirm Delete"
        open={confirmModalVisible}
        onCancel={() => setConfirmModalVisible(false)}
        onOk={handleDeleteUser}
      >
        <p>Are you sure you want to delete this user?</p>
      </Modal>
      <Modal
        title="Update Balance"
        open={updateModalVisible}
        onOk={handleModalOk}
        onCancel={() => setUpdateModalVisible(false)}
      >
        <Form>
          <Form.Item label="New Balance">
            <InputNumber
              value={newBalance}
              onChange={(value) => setNewBalance(value)}
              min={0}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Profile;
