import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Menu";
import dashboard from "./image/dashboard.png";
import User from "./image/profile.png";
import slider from "./image/left-and-right.png";
import products from "./image/products.png";
import order from "./image/order.png";

import product from "./image/add-item.png";
import logOut from "./image/log-out.png";
import Logoo from "./image/logo192.png";
import Facebook from "./image/facebook.jpg";
// import WhatSApp from "../image/whatisup.jpg";
import "./NavbarUpdate.css";
import axios from "axios";
const NavberUpdate = () => {
  const [state, setState] = React.useState({
    left: false,
  });
  //todo ==========================
  const [receivedCount, setReceivedCount] = React.useState(0);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://bardawamplus.com/api/orders");
        const count = response.data.filter((item) => item.status === 1).length;
        setReceivedCount(count);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  //todo ===============
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 350,
      }}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List sx={{ backgroundColor: "#white", padding: "20px" }}>
        <img
          src={Logoo}
          alt="logo"
          className="Logoo"
          height={100}
          width={100}
        />
        <ListItem button key="Dashboard" disablePadding>
          <Link
            to="/"
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={dashboard}
              alt="Dashboard"
              width={24}
              height={24}
              style={{ marginRight: "10px" }}
            />
            <ListItemText primary="dashboard" />
          </Link>
        </ListItem>
        <ListItem button key="AddItem" disablePadding>
          <Link
            to="/AddItem"
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={product}
              alt="AddItem"
              width={24}
              height={24}
              style={{ marginRight: "10px" }}
            />
            <ListItemText primary="AddItem" />
          </Link>
        </ListItem>
        {/* //todo ====================== */}
        <ListItem button key="Slider" disablePadding>
          <Link
            to="/Slider"
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={slider}
              alt="Slider"
              width={24}
              height={24}
              style={{ marginRight: "10px" }}
            />
            <ListItemText primary="Slider" />
          </Link>
        </ListItem>
        {/* //todo ====================== */}
        {/* //todo ====================== */}
        <ListItem button key="Products" disablePadding>
          <Link
            to="/Products"
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={products}
              alt="Products"
              width={24}
              height={24}
              style={{ marginRight: "10px" }}
            />
            <ListItemText primary="Products" />
          </Link>
        </ListItem>
        {/* //todo ====================== */}
        {/* //todo ====================== */}
        <ListItem button key="User" disablePadding>
          <Link
            to="/Profile"
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={User}
              alt="Profile"
              width={24}
              height={24}
              style={{ marginRight: "10px" }}
            />
            <ListItemText primary="Users" />
          </Link>
        </ListItem>
        {/* //todo ====================== */}
        {/* //todo ====================== */}
        <ListItem button key="OrderTable" disablePadding>
          <Link
            to="/OrderTable"
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={order}
              alt="OrderTable"
              width={24}
              height={24}
              style={{ marginRight: "10px" }}
            />
            <ListItemText
              primary={
                <>
                  Order Table
                  <span
                    style={{
                      backgroundColor: "#f50057", // Your background color
                      color: "white", // Text color
                      borderRadius: "12px",
                      padding: "2px 8px",
                      marginLeft: "10px",
                      fontSize: "0.875rem",
                    }}
                  >
                    {receivedCount}
                  </span>
                </>
              }
            />
          </Link>
        </ListItem>

        {/* //todo ====================== */}

        {[
          // { icon: dashboard, text: t("dashboard") },
          // { icon: product, text: t("Allproducts") },
          // { icon: currencies, text: t("currencies") },
          // { icon: stock, text: t("stock") },
        ].map(({ icon, text }) => (
          <ListItem button key={text} disablePadding>
            {icon && <img src={icon} alt="icon" width={24} height={24} />}
          </ListItem>
        ))}
      </List>
      <Divider />
      <List sx={{ backgroundColor: "#white", padding: "30px" }}>
        {[
          {
            icon: (
              <img
                src={logOut}
                alt="icon"
                width={24}
                height={24}
                style={{ marginRight: "10px" }}
              />
            ),
            text: "logout",

            onClick: () => {
              localStorage.removeItem("sessionToken");
              window.location.href = "/";
            },
          },
        ].map(({ icon, text, link, onClick }) => (
          <ListItem button key={text} disablePadding onClick={onClick}>
            {icon}
            <span style={{ marginLeft: "10px" }}>
              {link ? (
                <Link
                  to={link}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <ListItemText primary={text} />
                </Link>
              ) : (
                text
              )}
            </span>
          </ListItem>
        ))}
        <br />
        {/* <DarkMode /> */}
        <ul className="navbar-info">
          <li>Make by</li>
          <li>
            <img
              src={Facebook}
              alt="Facebook"
              width={24}
              height={24}
              style={{ marginRight: "10px" }}
            />
            <a
              href="https://www.facebook.com/TechTopia1998"
              target="_blank"
              rel="noopener noreferrer"
            >
              TechTopia
            </a>
          </li>
        </ul>
      </List>
    </Box>
  );
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div>
        {["left"].map((anchor) => (
          <React.Fragment key={anchor}>
            <Button
              variant="contained"
              color="success"
              onClick={toggleDrawer(anchor, true)}
              style={{ marginRight: "50px", borderRadius: 8 }}
              startIcon={<DashboardIcon />}
            >
              {anchor}
            </Button>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
              sx={{ "& .MuiDrawer-paper": { width: "250px" } }}
            >
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default NavberUpdate;
