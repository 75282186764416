import React, { useState } from "react";
import { Form, Button, Input, message, Checkbox } from "antd";
import TextArea from "antd/es/input/TextArea";
import { IconButton } from "@mui/material";
const AddItem = () => {
  const [form1] = Form.useForm();
  const [nameArabic, setNameArabic] = useState("");
  const [nameKurdish, setNameKurdish] = useState("");
  const [price, setPrice] = useState(0);
  const [image_url, setImage_url] = useState(null);
  const [descriptionArabic, setDescriptionArabic] = useState("");
  const [descriptionKurdish, setDescriptionKurdish] = useState("");
  const [pinned, setPinned] = useState(false);
  const [points, setPoints] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  //Todo post products
  const PostApi = async () => {
    if (!image_url) {
      // Handle case where image_url is null
      message.error("Please upload an image.");
      return;
    }
    try {
      let formData = new FormData();
      formData.append(
        "name",
        JSON.stringify({
          ar: nameArabic,
          ku: nameKurdish,
        })
      );
      formData.append(
        "description",
        JSON.stringify({
          ar: descriptionArabic,
          ku: descriptionKurdish,
        })
      );
      formData.append("price", price);
      formData.append("image_url", image_url);
      console.log(image_url);
      formData.append("discounted_price", discountedPrice);
      formData.append("points", points);
      formData.append("pinned", pinned ? 1 : 0);
      // Log formData content
      console.log("FormData:", formData);
      for (const pair of formData.entries()) {
        console.log(pair[0], pair[1]);
      }
      const res = await fetch("https://bardawamplus.com/api/products", {
        method: "POST",
        body: formData,
      });
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      const data = await res.json();
      console.log("Response:", data);
      // Handle success message here
      message.success("Form submitted successfully");
    } catch (error) {
      console.error("Error:", error);
      // Handle error message here
      message.error("Failed to submit form. Please try again later.");
    }
  };
  //todo ---------------------------------------------------------------------
  const onFinish = (values) => {
    // console.log("Success:", values);
    // message.success("Success Post ");
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    message.error("Error");
  };
  const handleResetCart = () => {
    form1.resetFields();
  };
  //todo  -----------------------------------------------
  //todo  ----------------------------------------------
  //todo  -----------------------------------------------
  //e && e.fileList ? [e.file] : []
  const normFile = (e) => {
    return e && e.fileList ? [e.file] : [];
  };
  // const onChangeUpload = ({ file, target, fileList }) => {
  //   console.log("file of image ", file);
  //   console.log("target of image ", target);
  //   console.log("list of image ",fileList );
  //   // Check if the event was triggered by an Ant Design Upload component
  //   if (file) {
  //     let URI = file;
  //     console.log("This is image ", URI);
  //     if (file.status === "done") {
  //       // eslint-disable-next-line no-undef
  //       setImage_url(URI);
  //     } else if (file.status === "removed") {
  //       setImage_url(null);
  //     }
  //   } else if (target) {
  //     if (target.files.length > 0) {
  //       const file = target.files[0];
  //       setImage_url(URL.createObjectURL(file));
  //       console.log("URL.createObjectURL(file)", URL.createObjectURL(file));
  //     }
  //   }
  // };

  const onChangeUpload = (e) => {
    if (e && e.target && e.target.files) {
      const { target } = e;
      console.log("Target from file input:", target);

      if (target.files.length > 0) {
        const file = target.files[0];
        console.log("pricked file", file);
        setImage_url(file);
      }
    }
    // If neither condition is met, log the event for debugging
    else {
      console.log("Unexpected event:", e);
    }
  };

  const onChangeCheckbox = (e) => {
    setPinned(e.target.checked);
  };
  // const onChangeUpload = ({ file, fileList }) => {
  //   let URI = file;
  //   console.log(URI);
  //   if (file.status === "done") {
  //     // eslint-disable-next-line no-undef
  //     setImage_url(URI);
  //   } else if (file.status === "removed") {
  //     setImage_url(null);
  //   }
  // };
  //todo  -----------------------------------------------
  //todo  -----------------------------------------------
  const layout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 16,
    },
  };
  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1 style={{ fontSize: "24px", marginBottom: "20px" }}>Add Cart Item</h1>
      <Form
        form={form1}
        {...layout}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {/* // todo this iS Imagess */}
        <Form.Item
          name="file"
          label="Image"
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <input
            // accept="image/*"
            id="image-upload"
            type="file"
            style={{ display: "none" }}
            onChange={onChangeUpload}
          />
          <label htmlFor="image-upload">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              add image
            </IconButton>
          </label>
          {image_url && (
            <img
              src={URL.createObjectURL(image_url)}
              alt="Uploaded"
              style={{ maxWidth: "100px", maxHeight: "100px" }}
            />
          )}
        </Form.Item>

        {/* //! end image ================================= */}
        <Form.Item
          name="TitleKurdish"
          label="Title Kurdish"
          // rules={[
          //   {
          //     required: true,
          //     message: "Input your Title Kurdish",
          //   },
          // ]}
        >
          <Input
            placeholder="Type your Title Kurdish"
            value={nameKurdish}
            onChange={(e) => setNameKurdish(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          name="TitleArabic"
          label="Title Arabic"
          // rules={[
          //   {
          //     required: true,
          //     message: "Input your Title Arabic",
          //   },
          // ]}
        >
          <Input
            placeholder="Type your Title Arabic"
            value={nameArabic}
            onChange={(e) => setNameArabic(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          name="Price"
          label="Price"
          // rules={[
          //   {
          //     required: true,
          //     message: "Input your Price",
          //   },
          // ]}
        >
          <Input
            placeholder="Type your Price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          name="Discount"
          label="Discount"
          // rules={[
          //   {
          //     required: true,
          //     message: "Input your discount",
          //   },
          // ]}
        >
          <Input
            placeholder="Type your discount"
            value={discountedPrice}
            onChange={(e) => setDiscountedPrice(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          name="points"
          label="points"
          // rules={[
          //   {
          //     required: true,
          //     message: "Input your points",
          //   },
          // ]}
        >
          <Input
            placeholder="Type your points"
            value={points}
            onChange={(e) => setPoints(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          name="DescriptionKurdish"
          label="Description Kurdish"
          // rules={[
          //   {
          //     required: true,
          //     message: "Input your Description Kurdish",
          //   },
          // ]}
        >
          <TextArea
            rows={3}
            name="DescriptionKurdish"
            value={descriptionKurdish}
            onChange={(e) => setDescriptionKurdish(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          name="DescriptionArabic"
          label="Description Arabic"
          // rules={[
          //   {
          //     required: true,
          //     message: "Input your Description Arabic",
          //   },
          // ]}
        >
          <TextArea
            rows={3}
            name="DescriptionArabic"
            value={descriptionArabic}
            onChange={(e) => setDescriptionArabic(e.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Checkbox checked={pinned} onChange={onChangeCheckbox}>
            Add Slider
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" onClick={PostApi}>
            Submit
          </Button>
          <span style={{ margin: "0 8px" }}></span>
          <Button type="primary" danger onClick={handleResetCart}>
            Reset
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default AddItem;
